import { render, staticRenderFns } from "./homepage-desktop.vue?vue&type=template&id=1d10e222&scoped=true&"
import script from "./homepage-desktop.vue?vue&type=script&lang=js&"
export * from "./homepage-desktop.vue?vue&type=script&lang=js&"
import style0 from "./homepage-desktop.vue?vue&type=style&index=0&id=1d10e222&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d10e222",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCarousel,VCarouselItem,VDivider,VForm,VImg,VLayout,VTextField})
