<template>
  <div>
    <v-carousel
      v-if="widthNow >= 1480"
      :show-arrows="false"
      delimiter-icon="mdi-square"
      hide-delimiters
      light
      :height="heightCarousel"
      deli
      hide-delimiter-background
    >
      <v-carousel-item v-for="(slide, i) in slides" :key="i">
        <div
          class="Form-Hero"
          v-bind:style="{
            'margin-left': marginLeft,
            width: width,
            'margin-top': marginTop,
          }"
        >
          <v-card class="pa-8 registForm" elevation="0">
            <v-form @submit.prevent="enterData">
              <div
                class="registTitle"
                v-bind:style="{
                  'font-size': registFontTitle,
                }"
              >
                Formulir Pendaftaran
              </div>
              <div
                class="registSubtitle"
                v-bind:style="{
                  'font-size': registFontSubtitle,
                }"
              >
                Silahkan masukkan data diri dengan benar untuk melanjutkan proses pendaftaran
              </div>
              <v-divider class="divider" />
              <div
                class="registLabel"
                v-bind:style="{
                  'font-size': registFontLabel,
                }"
              >
                Nomor KTP<strong class="redLink">*</strong>
              </div>
              <v-text-field
                v-model="registForm.ktp"
                class="registInput"
                outlined
                dense
              ></v-text-field>
              <div
                class="registLabel"
                v-bind:style="{
                  'font-size': registFontLabel,
                }"
              >
                Alamat Email<strong class="redLink">*</strong>
              </div>
              <v-text-field
                v-model="registForm.email"
                class="registInput"
                outlined
                dense
              ></v-text-field>
              <div
                class="registLabel"
                v-bind:style="{
                  'font-size': registFontLabel,
                }"
              >
                Nomor Ponsel<strong class="redLink">*</strong>
              </div>
              <v-text-field
                v-model="registForm.hp"
                class="registInput"
                outlined
                dense
              ></v-text-field>
              <v-btn type="submit" class="mx-auto registButton"
                >Daftar Sekarang!</v-btn
              >
            </v-form>
          </v-card>
        </div>
        <div
          class="Text-Hero"
          v-bind:style="{
            'font-size': fontMain,
            'margin-left': marginLeftMain,
            width: width,
            'margin-top': marginTopMain,
          }"
        >
          <p>Pinjaman Aman bersama Dompet Kilat!</p>
          <p
            class="Sub-Hero"
            v-bind:style="{
              'font-size': fontBannerSub,
              'margin-top': marginTopSub,
            }"
          >
            Ajukan pinjaman sampai dengan
            <strong class="redLink">Rp 50 juta</strong> untuk memenuhi berbagai
            kebutuhanmu!
          </p>
        </div>
        <img src="../../assets/HomePage/Hero-Image.webp" class="Hero" />
      </v-carousel-item>
    </v-carousel>
    <v-img src="../../assets/HomePage/Hero-Image.webp" v-if="widthNow <= 1480">
      <v-layout align-center column style="margin-top: 100px">
        <p style="margin-top: 100px" class="Section-Title">
          Pinjaman Aman bersama Dompet Kilat!
        </p>
        <p
          style="margin-top: 20px; width: 328px;
height: 36px; text-aligncenter"
        >
          Ajukan pinjaman sampai dengan
          <strong class="redLink">Rp 50 Juta</strong> untuk memenuhi berbagai
          kebutuhanmu!
        </p>
        <v-card class="pa-8 registForm" elevation="0" v-if="widthNow <= 1480">
          <v-form @submit.prevent="enterData">
            <div
              class="registTitle"
              v-bind:style="{
                'font-size': registFontTitle,
              }"
            >
              Formulir Pendaftaran
            </div>
            <div
              class="registSubtitle"
              v-bind:style="{
                'font-size': registFontSubtitle,
              }"
            >
              Silahkan masukkan data diri dengan benar untuk melanjutkan proses pendaftaran
            </div>
            <v-divider class="divider" />
            <div
              class="registLabel"
              v-bind:style="{
                'font-size': registFontLabel,
              }"
            >
              Nomor KTP<strong class="redLink">*</strong>
            </div>
            <v-text-field
              v-model="registForm.ktp"
              class="registInput"
              outlined
              dense
            ></v-text-field>
            <div
              class="registLabel"
              v-bind:style="{
                'font-size': registFontLabel,
              }"
            >
              Alamat Email<strong class="redLink">*</strong>
            </div>
            <v-text-field
              v-model="registForm.email"
              class="registInput"
              outlined
              dense
            ></v-text-field>
            <div
              class="registLabel"
              v-bind:style="{
                'font-size': registFontLabel,
              }"
            >
              Nomor Ponsel<strong class="redLink">*</strong>
            </div>
            <v-text-field
              v-model="registForm.hp"
              class="registInput"
              outlined
              dense
            ></v-text-field>
            <v-btn type="submit" class="mx-auto registButton"
              >Daftar Sekarang!</v-btn
            >
          </v-form>
        </v-card>
      </v-layout>
    </v-img>

    <KeuntunganDesktop />
    <!-- <CaraKerjaDesktop /> -->
    <PanduanDesktop />
    <SyaratKetentuanDesktop class="sk-margin" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import email from "../../../api-compro";
import instance from "../../../myconfig.js";
import KeuntunganDesktop from "../keuntungan/keuntungan-desktop";
// import KeuntunganMobile from "../keuntungan/keuntungan-mobile";
// import CaraKerjaDesktop from "../carakerja/carakerja-desktop";
// import CaraKerjaMobile from "../carakerja/carakerja-mobile";
import PanduanDesktop from "../panduan/panduan-desktop";
// import PanduanMobile from "../panduan/panduan-mobile";
import SyaratKetentuanDesktop from "../syaratketentuan/syaratketentuan-desktop";
// import SyaratKetentuanMobile from "../syaratketentuan/syaratketentuan-mobile";

export default {
  components: {
    KeuntunganDesktop,
    PanduanDesktop,
    SyaratKetentuanDesktop,
  },

  data() {
    return {
      heroTextSize: `48px`,
      widthNow: window.innerWidth,
      marginLeft: `${window.innerWidth / 8}px`,
      marginTop: `${window.innerWidth / 10}px`,
      width: `${window.innerWidth / 4}px`,
      heightCarousel: window.innerWidth / 2.25 + "px",
      fontSize: `${window.innerWidth / 8}px`,
      fontMain: `${window.innerWidth / 8}px`,
      marginLeftMain: `${window.innerWidth / 8}px`,
      marginTopMain: `${window.innerWidth / 8}px`,
      marginTopSub: `${window.innerWidth / 10}px`,
      registFontTitle: `${window.innerWidth / 70}px`,
      registFontSubtitle: `${window.innerWidth / 130}px`,
      registFontLabel: `${window.innerWidth / 130}px`,
      registForm: {
        ktp: "",
        email: "",
        hp: "",
      },
      slides: [
        {
          title: "Mudah",
          content:
            "Proses pendanaan dan peminjaman secara online secara sederhana dan mudah dipahami",
          icon: "Mudah.png",
        },
        {
          title: "Cerdas",
          content:
            "Risiko terukur dengan menggunakan teknologi terbaru untuk menganalisis risiko",
          icon: "Cerdas.png",
        },
        {
          title: "Aman",
          content:
            "Mengantongi izin usaha resmi dan diawasi langsung oleh OJK (KEP-47/D.05/2019)",
          icon: "aman.png",
        },
      ],
    };
  },
  mounted() {
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler);
  },
  created() {
    this.resizeEventHandler();
    window.addEventListener("resize", this.resizeEventHandler);
  },
  methods: {
    resizeEventHandler() {
      this.widthNow = window.innerWidth;
      this.marginLeft = `${window.innerWidth / 8}px`;
      this.marginTop = `${window.innerWidth / 12}px`;
      this.width = `${window.innerWidth / 3}px`;
      this.fontSize = `${window.innerWidth / 40}px`;
      this.fontMain = `${window.innerWidth / 40}px`;
      this.marginLeftMain = `${window.innerWidth / 8}px`;
      this.marginTopMain = `${window.innerWidth / 8}px`;
      this.marginTopSub = `${window.innerWidth / 50}px`;
      this.heightCarousel = window.innerWidth / 2.25 + "px";
      this.registFontTitle = `${window.innerWidth / 70}px`;
      this.registFontSubtitle = `${window.innerWidth / 130}px`;
      this.registFontLabel = `${window.innerWidth / 130}px`;
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    enterData() {
      console.log("tes");

      // let terkirim = 0

      instance
        .get(`/scoring/${this.registForm.ktp}/bni_payroll`, {
          auth: {
            username: "scoringdk2020",
            password: "ZGt1c2VyMjAyMAo=",
          },
        })
        .then(({ data }) => {
          console.log(data, "halooo");
          let dataFDC = Buffer.from(data.data, "base64").toString("ascii");
          console.log(dataFDC);
          let jsonDataFDC = JSON.parse(dataFDC);
          return jsonDataFDC;
        })
        .then((jsonDataFDC) => {
          console.log(jsonDataFDC);
          if (
            jsonDataFDC.status == "APPROVED" ||
            jsonDataFDC.status == "REJECTED"
          ) {
            console.log("lancar");
            let ams = {
              nik: this.registForm.ktp,
              email: this.registForm.email,
              phone: this.registForm.hp,
            };
            return ams;
          }
        })
        .then((ams) => {
          instance
            .post(`/data/ams/register`, ams, {
              auth: {
                username: "scoringdk2020",
                password: "ZGt1c2VyMjAyMAo=",
              },
            })
            .then(({ data }) => {
              console.log(data, "halo bandung");
              if (data.data == undefined) {
                instance
                  .post(`/data/ams/register`, ams, {
                    auth: {
                      username: "scoringdk2020",
                      password: "ZGt1c2VyMjAyMAo=",
                    },
                  })
                  .then(({ data }) => {
                    console.log(data, "halo bandung");
                    if (data.data == undefined) {
                      Swal.fire({
                        title: "Gagal Koneksi!",
                        text: "Terjadi gangguan tolong diulangi lagi!",
                        icon: "error",
                      });
                    } else {
                      let dataAms = Buffer.from(data.data, "base64").toString(
                        "ascii"
                      );
                      console.log(dataAms, "kentot");
                      let jsonDataAms = JSON.parse(dataAms);
                      console.log(jsonDataAms);
                      if (data.status == true && jsonDataAms.status == "OK") {
                        let mail = {
                          to: this.registForm.email,
                          subject: "Ini username dan Password Anda",
                          from: "test-noreply@dompetkilat.co.id",
                          body: `<!DOCTYPE html>
      <html>

      <head>

          <meta charset="utf-8">
          <meta http-equiv="x-ua-compatible" content="ie=edge">
          <title>Password Reset</title>
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <style type="text/css">
              /**
               * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
               */
              @media screen {
                  @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 400;
                      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                  }

                  @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 700;
                      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                  }
              }

              /**
               * Avoid browser level font resizing.
               * 1. Windows Mobile
               * 2. iOS / OSX
               */
              body,
              table,
              td,
              a {
                  -ms-text-size-adjust: 100%;
                  /* 1 */
                  -webkit-text-size-adjust: 100%;
                  /* 2 */
              }

              /**
               * Remove extra space added to tables and cells in Outlook.
               */
              table,
              td {
                  mso-table-rspace: 0pt;
                  mso-table-lspace: 0pt;
              }

              /**
               * Better fluid images in Internet Explorer.
               */
              img {
                  -ms-interpolation-mode: bicubic;
              }

              /**
               * Remove blue links for iOS devices.
               */
              a[x-apple-data-detectors] {
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  color: inherit !important;
                  text-decoration: none !important;
              }

              /**
               * Fix centering issues in Android 4.4.
               */
              div[style*="margin: 16px 0;"] {
                  margin: 0 !important;
              }

              body {
                  width: 100% !important;
                  height: 100% !important;
                  padding: 0 !important;
                  margin: 0 !important;
              }

              /**
               * Collapse table borders to avoid space between cells.
               */
              table {
                  border-collapse: collapse !important;
              }

              a {
                  color: #1a82e2;
              }

              img {
                  height: auto;
                  line-height: 100%;
                  text-decoration: none;
                  border: 0;
                  outline: none;
              }

              .col-5 {
                  width: 40%;
              }

              .col-7 {
                  width: 60%;
              }
          </style>

      </head>

      <body style="background-color: #e9ecef;">

          <!-- start preheader -->
          <div class="preheader"
              style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
              Username dan Password anda untuk masuk kedalam aplikasi Dompet Kilat.
          </div>
          <!-- end preheader -->

          <!-- start body -->
          <table border="0" cellpadding="0" cellspacing="0" width="100%">

              <!-- start logo -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                              <td align="center" valign="top" style="padding: 36px 24px;">
                                  <a href="https://sendgrid.com" target="_blank" style="display: inline-block;">
                                  </a>
                              </td>
                          </tr>
                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end logo -->

              <!-- start hero -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 36px 24px 0; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; border-top: 3px solid #d4dadf;">
                                  <h1
                                      style="margin: 0; font-size: 32px; font-weight: 700; letter-spacing: -1px; line-height: 48px;">
                                      Ini Adalah Username dan Password Anda!</h1>
                              </td>
                          </tr>
                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end hero -->

              <!-- start copy block -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                          <!-- start copy -->
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Ini adalah username dan password anda untuk masuk kedalam aplikasi
                                      Dompet Kilat untuk produk BNI Payroll anda :</p>
                              </td>
                          </tr>
                          <!-- end copy -->

                          <!-- start button -->
                          <tr>
                              <td align="left" bgcolor="#ffffff">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                      <tr>
                                          <td align="center" bgcolor="#ffffff" style="padding: 12px;">
                                              <div class="card"
                                                  style=" background: linear-gradient(24deg, rgb(248, 146, 102), rgb(232, 77, 87));">
                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0" style="color:  white;  font-weight: bolder;
                                                        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;">
                                                      <tr>
                                                          <td align="left"  style="border-radius: 6px; height: 75px;">
                                                              <div>
                                                                  <span
                                                                      style="display:inline-block;  width:100px; margin-left: 10px; margin-bottom: 10px;">Username</span>
                                                                  <span>${jsonDataAms.response.username}</span>
                                                              </div>
                                                              <div>
                                                                  <span
                                                                      style="display:inline-block;  margin-left: 10px; width:100px;">Password</span>
                                                                  <span>${jsonDataAms.response.password}</span>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </div>

                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <!-- end button -->

                          <!-- start copy -->
                          <tr>
                              <td align=" left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Untuk masuk ke aplikasi Dompet Kilat,
                                      dengan mengunduh aplikasi Dompet
                                      Kilat di Google Play Store :</p>
                                  <table border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                          <td  align="center" style="border-radius: 6px;">
                                             <a  style="text-decoration: none;" href="https://drive.google.com/file/d/1ug4yr8r-37X_zuj1BthgE1LNAY1dXQ3A/view?usp=drivesdk">Tautan Unduh APK</a>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <!-- end copy -->

                          <!-- start copy -->
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                  <p style="margin: 0;">Terima Kasih</p>
                              </td>
                          </tr>
                          <!-- end copy -->

                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end copy block -->

              <!-- start footer -->
              <tr>
                  <td align="center" bgcolor="#e9ecef" style="padding: 24px;">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                          <!-- start permission -->
                          <tr>
                              <td align="center" bgcolor="#e9ecef"
                                  style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                                  <p style="margin: 0;">Anda hanya mendapatkan email ini sekali, jika anda ingin menghapus
                                      email ini untuk keamanan dipersilahkan cuman tolong dihafalkan username dan password
                                      nya.</p>
                              </td>
                          </tr>
                          <!-- end permission -->

                          <!-- start unsubscribe -->
                          <tr>
                              <td align="center" bgcolor="#e9ecef"
                                  style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                                  <p style="margin: 0;">Lokasi : THAMRIN NINE TOWN HOUSE Unit 8A
                                      Jl. Sungai Gerong No. 1 – 1a
                                      Kebon Melati, Jakarta Pusat 10230
                                      (lokasi ada di sebrang hotel Liberty)
                                  </p>
                                  <p style="margin: 0;">Kontak : (021) 400-00855
                                  </p>
                              </td>
                          </tr>
                          <!-- end unsubscribe -->

                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end footer -->

          </table>
          <!-- end body -->

      </body>

      </html>`,
                        };
                        email
                          .post(`/mail`, mail)
                          .then(({ data }) => {
                            console.log(data, "tes");

                            Swal.fire({
                              title: "Anda sudah terdaftar!",
                              text:
                                "Tolong buka Email yang anda sudah daftarkan!",
                              icon: "success",
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      } else if (jsonDataAms.status == "FAILED") {
                        Swal.fire({
                          title: "Gagal!",
                          text: jsonDataAms.response.message,
                          icon: "error",
                        });
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    Swal.fire({
                      title: "Gagal Koneksi!",
                      text: "Terjadi gangguan tolong diulangi lagi!",
                      icon: "error",
                    });
                  });
              } else {
                let dataAms = Buffer.from(data.data, "base64").toString(
                  "ascii"
                );
                console.log(dataAms, "kentot");
                let jsonDataAms = JSON.parse(dataAms);
                console.log(jsonDataAms, "mane");
                if (data.status == true && jsonDataAms.status == "OK") {
                  let mail = {
                    to: this.registForm.email,
                    subject: "Ini username dan Password Anda",
                    from: "test-noreply@dompetkilat.co.id",
                    body: `<!DOCTYPE html>
      <html>

      <head>

          <meta charset="utf-8">
          <meta http-equiv="x-ua-compatible" content="ie=edge">
          <title>Password Reset</title>
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <style type="text/css">
              /**
               * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
               */
              @media screen {
                  @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 400;
                      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                  }

                  @font-face {
                      font-family: 'Source Sans Pro';
                      font-style: normal;
                      font-weight: 700;
                      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                  }
              }

              /**
               * Avoid browser level font resizing.
               * 1. Windows Mobile
               * 2. iOS / OSX
               */
              body,
              table,
              td,
              a {
                  -ms-text-size-adjust: 100%;
                  /* 1 */
                  -webkit-text-size-adjust: 100%;
                  /* 2 */
              }

              /**
               * Remove extra space added to tables and cells in Outlook.
               */
              table,
              td {
                  mso-table-rspace: 0pt;
                  mso-table-lspace: 0pt;
              }

              /**
               * Better fluid images in Internet Explorer.
               */
              img {
                  -ms-interpolation-mode: bicubic;
              }

              /**
               * Remove blue links for iOS devices.
               */
              a[x-apple-data-detectors] {
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  color: inherit !important;
                  text-decoration: none !important;
              }

              /**
               * Fix centering issues in Android 4.4.
               */
              div[style*="margin: 16px 0;"] {
                  margin: 0 !important;
              }

              body {
                  width: 100% !important;
                  height: 100% !important;
                  padding: 0 !important;
                  margin: 0 !important;
              }

              /**
               * Collapse table borders to avoid space between cells.
               */
              table {
                  border-collapse: collapse !important;
              }

              a {
                  color: #1a82e2;
              }

              img {
                  height: auto;
                  line-height: 100%;
                  text-decoration: none;
                  border: 0;
                  outline: none;
              }

              .col-5 {
                  width: 40%;
              }

              .col-7 {
                  width: 60%;
              }
          </style>

      </head>

      <body style="background-color: #e9ecef;">

          <!-- start preheader -->
          <div class="preheader"
              style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
              Username dan Password anda untuk masuk kedalam aplikasi Dompet Kilat.
          </div>
          <!-- end preheader -->

          <!-- start body -->
          <table border="0" cellpadding="0" cellspacing="0" width="100%">

              <!-- start logo -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                              <td align="center" valign="top" style="padding: 36px 24px;">
                                  <a href="https://sendgrid.com" target="_blank" style="display: inline-block;">
                                  </a>
                              </td>
                          </tr>
                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end logo -->

              <!-- start hero -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 36px 24px 0; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; border-top: 3px solid #d4dadf;">
                                  <h1
                                      style="margin: 0; font-size: 32px; font-weight: 700; letter-spacing: -1px; line-height: 48px;">
                                      Ini Adalah Username dan Password Anda!</h1>
                              </td>
                          </tr>
                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end hero -->

              <!-- start copy block -->
              <tr>
                  <td align="center" bgcolor="#e9ecef">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                          <!-- start copy -->
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Ini adalah username dan password anda untuk masuk kedalam aplikasi
                                      Dompet Kilat untuk produk BNI Payroll anda :</p>
                              </td>
                          </tr>
                          <!-- end copy -->

                          <!-- start button -->
                          <tr>
                              <td align="left" bgcolor="#ffffff">
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                      <tr>
                                          <td align="center" bgcolor="#ffffff" style="padding: 12px;">
                                              <div class="card"
                                                  style=" background: linear-gradient(24deg, rgb(248, 146, 102), rgb(232, 77, 87));">
                                                  <table width="100%" border="0" cellpadding="0" cellspacing="0" style="color:  white;  font-weight: bolder;
                                                        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;">
                                                      <tr>
                                                          <td align="left"  style="border-radius: 6px; height: 75px;">
                                                              <div>
                                                                  <span
                                                                      style="display:inline-block;  width:100px; margin-left: 10px; margin-bottom: 10px;">Username</span>
                                                                  <span>${jsonDataAms.response.username}</span>
                                                              </div>
                                                              <div>
                                                                  <span
                                                                      style="display:inline-block;  margin-left: 10px; width:100px;">Password</span>
                                                                  <span>${jsonDataAms.response.password}</span>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  </table>
                                              </div>

                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <!-- end button -->

                          <!-- start copy -->
                          <tr>
                              <td align=" left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Untuk masuk ke aplikasi Dompet Kilat,
                                      dengan mengunduh aplikasi Dompet
                                      Kilat di Google Play Store :</p>
                                  <table border="0" cellpadding="0" cellspacing="0">
                                      <tr>
                                          <td  align="center" style="border-radius: 6px;">
                                             <a  style="text-decoration: none;" href="https://drive.google.com/file/d/1ug4yr8r-37X_zuj1BthgE1LNAY1dXQ3A/view?usp=drivesdk">Tautan Unduh APK</a>
                                          </td>
                                      </tr>
                                  </table>
                              </td>
                          </tr>
                          <!-- end copy -->

                          <!-- start copy -->
                          <tr>
                              <td align="left" bgcolor="#ffffff"
                                  style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                  <p style="margin: 0;">Terima Kasih</p>
                              </td>
                          </tr>
                          <!-- end copy -->

                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end copy block -->

              <!-- start footer -->
              <tr>
                  <td align="center" bgcolor="#e9ecef" style="padding: 24px;">
                      <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tr>
                    <td align="center" valign="top" width="600">
                    <![endif]-->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                          <!-- start permission -->
                          <tr>
                              <td align="center" bgcolor="#e9ecef"
                                  style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                                  <p style="margin: 0;">Anda hanya mendapatkan email ini sekali, jika anda ingin menghapus
                                      email ini untuk keamanan dipersilahkan cuman tolong dihafalkan username dan password
                                      nya.</p>
                              </td>
                          </tr>
                          <!-- end permission -->

                          <!-- start unsubscribe -->
                          <tr>
                              <td align="center" bgcolor="#e9ecef"
                                  style="padding: 12px 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 14px; line-height: 20px; color: #666;">
                                  <p style="margin: 0;">Lokasi : THAMRIN NINE TOWN HOUSE Unit 8A
                                      Jl. Sungai Gerong No. 1 – 1a
                                      Kebon Melati, Jakarta Pusat 10230
                                      (lokasi ada di sebrang hotel Liberty)
                                  </p>
                                  <p style="margin: 0;">Kontak : (021) 400-00855
                                  </p>
                              </td>
                          </tr>
                          <!-- end unsubscribe -->

                      </table>
                      <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
                  </td>
              </tr>
              <!-- end footer -->

          </table>
          <!-- end body -->

      </body>

      </html>`,
                  };
                  email
                    .post(`/mail`, mail)
                    .then(({ data }) => {
                      console.log(data, "tes");

                      Swal.fire({
                        title: "Anda sudah terdaftar!",
                        text: "Tolong buka Email yang anda sudah daftarkan!",
                        icon: "success",
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (jsonDataAms.status == "FAILED") {
                  Swal.fire({
                    title: "Gagal!",
                    text: jsonDataAms.response.message,
                    icon: "error",
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                title: "Gagal Koneksi!",
                text: "Terjadi gangguan tolong diulangi lagi!",
                icon: "error",
              });
            });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Gagal Koneksi!",
            text: "Terjadi gangguan tolong diulangi lagi!",
            icon: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.Section-Title {
  width: 400px;
  height: 30px;
  flex-grow: 0;
  margin: 0px auto 24px auto;
  font-family: Sora;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}
.Hero {
  width: 100%;
  object-fit: contain;
}
.Form-Hero {
  width: 590px;
  margin: 137px 810px 0 200px;
  font-family: Sora;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  position: absolute;
  left: 50%;
}
.Text-Hero {
  width: 590px;
  margin: 137px 810px 0 200px;
  font-family: Sora;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  position: absolute;
}
.Sub-Hero {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
.divider {
  border-color: #f1f1f1 !important;
}
.registForm {
  flex-grow: 0;
  max-width: 437px;
  max-height: 561px;
  border-radius: 12px !important;
  background-color: #ffffff;
}
.registTitle {
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  /* font-size: 24px; */
  /* line-height: 30px; */
  color: #252525;
}
.registSubtitle {
  margin-top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  /* font-size: 15px;
  line-height: 24px; */
  color: #252525;
}
.registLabel {
  margin-bottom: 10px;
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #252525;
  letter-spacing: 0.5px;
}
.registInput {
  border-radius: 12px !important;
  border-color: #252525 !important;
}
.registButton {
  text-transform: none;
  width: 100%;
  height: 48px !important;
  flex-grow: 0;
  color: white;
  font-family: Inter;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  padding: 14px 75px 14px 76px;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  background-color: #d3352c !important;
}
.redLink {
  color: #d3352c;
}
.sk-margin {
  margin-bottom: 100px;
}
.v-text-field--outlined >>> fieldset {
  border-color: #f1f1f1;
  border-width: 2px;
}
</style>
