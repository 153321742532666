<template>
  <v-layout align-center column style="margin-top:100px;">
    <p class="Section-Title">Syarat & Ketentuan</p>
    <v-row>
      <v-col>
        <v-skeleton-loader
          type="image"
          class="Career-Image"
          v-if="!isImgLoaded"
        ></v-skeleton-loader>
        <img
          src="../../assets/SyaratKetentuan/Career-Image.png"
          @load="onImgLoad"
          class="Career-Image"
        />
      </v-col>
      <v-col>
        <v-row class="pt-8">
          <span class="Ellipse">
            <p class="Value">1</p>
          </span>
          <span style="width: 80%;">
            <p class="SubDetail">
              Warga negara Indonesia dan memiliki KTP.
            </p>
          </span>
        </v-row>
        <v-row class="pt-4">
          <span class="Ellipse">
            <p class="Value">2</p>
          </span>
          <span style="width: 80%;">
            <p class="SubDetail">
              Terdaftar sebagai karyawan aktif di perusahaan yang bermitra
              dengan Dompet Kilat.
            </p>
          </span>
        </v-row>
        <v-row class="pt-4">
          <span class="Ellipse">
            <p class="Value">3</p>
          </span>
          <span style="width: 80%;">
            <p class="SubDetail">
              Pinjaman maksimal sampai Rp 50 Juta dengan maksimal tenor 6 bulan.
            </p>
          </span>
        </v-row>
        <v-row class="pt-4">
          <span class="Ellipse">
            <p class="Value">4</p>
          </span>
          <span style="width: 80%;">
            <p class="SubDetail">
              Suku bunga ringan dengan 2,5% per bulan.
            </p>
          </span>
        </v-row>
        <v-row class="pt-4">
          <span class="Ellipse">
            <p class="Value">5</p>
          </span>
          <span style="width: 80%;">
            <p class="SubDetail">
              Pembayaran cicilan langsung dipotong dari gaji setiap bulannya.
              Tidak perlu khawatir denda jatuh tempo.
            </p>
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      isImgLoaded: false,
    };
  },

  methods: {
    onImgLoad() {
      this.isImgLoaded = true;
    },
  },
};
</script>

<style scoped>
.Section-Title {
  width: 277px;
  height: 30px;
  flex-grow: 0;
  margin: 0px 309px 40px;
  font-family: Sora;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}
.Career-Image {
  width: 490px;
  height: 360px;
  flex-grow: 0;
  margin: 0 20px 0 0;
  object-fit: contain;
}
.Ellipse {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  margin: 0 auto 8px auto;
  padding: 6px 10px 0px;
  border-radius: 80%;
  background-color: #d3352c;
}
.Value {
  flex-grow: 0;
  font-family: Sora;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 4px;
}
.SubDetail {
  max-width: 500px;
  flex-grow: 0;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}
@media screen and (max-width: 1200px) {
  .Career-Image {
    width: 440px;
    height: 320px;
    flex-grow: 0;
    margin: 0 10px 0 0;
    object-fit: contain;
  }
  .SubDetail {
    max-width: 400px;
    flex-grow: 0;
    margin: 8px 0 0;
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
}
@media screen and (max-width: 1024px) {
  .Career-Image {
    width: 360px;
    height: 240px;
    flex-grow: 0;
    margin: 0 10px 0 0;
    object-fit: contain;
  }
  .SubDetail {
    max-width: 350px;
    flex-grow: 0;
    margin: 8px 0 0;
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #252525;
  }
}
</style>
